<template>
  <ul class="footer-nav">
    <li
      v-for="item in footerMenu"
      :key="item?.id"
    >
      <NuxtLink
        :to="getLink(item?.path)"
        class="footer-nav-link"
      >
        {{ item?.title }}
      </NuxtLink>
    </li>
  </ul>
</template>

<script setup lang="ts">
import type { FooterNavItem } from '~/components/layout/Footer.vue';

const { footerMenu } = defineProps<{
  footerMenu: FooterNavItem[]
}>();

const getLink = (link: string) => {
  if (link.includes('http://') || link.includes('https://')) {
    return link
  }
  if (link[0] === '/') {
    return link
  }
  return `/${ link }`
}
</script>

<style scoped lang="scss">
.footer-nav {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px 90px;
}

.footer-nav-link {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;

  &:hover {
    text-decoration: underline;
  }
}

@media (max-width: 1359px) {
  .footer-nav {
    grid-gap: 16px 32px;
  }
}
@media (max-width: 1023px) {
  .footer-nav-link {
    font-size: 12px;
  }
}
</style>

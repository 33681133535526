<template>
  <div>
    <div class="root-layout">
      <div class="root-layout__header">
        <TheHeader />
      </div>

      <div class="root-layout__body">
        <slot />
      </div>

      <template v-if="isVisibleReviewsBlock">
        <ReviewsBlock
          cfgs="{}"
          extras="{}"
          :values="{heading: 'Отзывы'}"
        />
      </template>

      <div
        class="root-layout__footer"
        :class="classFooter"
      >
        <Footer />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TheHeader from '~/components/layout/Header.vue';
import Footer from '~/components/layout/Footer.vue';
import type { ComputedRef } from 'vue';
import { appConfigStore as useAppConfigStore } from '#sitis/stores/modules/app-store';

const appConfigStore = useAppConfigStore();
const ReviewsBlock = defineAsyncComponent(() => import('~/cms/blocks/ReviewsBlock.vue'));
const isVisibleReviewsBlock: ComputedRef<boolean> = computed(() => {
  const { path, name } = useRoute();
  if (['/', '/contact-us'].includes(path)) {
    return false
  }
  if (['3d-projects', '3d-projects-slug'].includes(String(name))) {
    return false
  }
  return true
});

const classFooter: ComputedRef<string> = computed(() => {
  const { path } = useRoute();

  let _list: string[] = [];
  if (['/', '/kak-sdelat-zakaz', '/articles'].includes(path)) {
    _list.push('mt-0')
  }

  return _list.join(' ')
});
</script>

<style lang="scss" scoped>
</style>

<template>
  <div
    class="header"
    :class="{'--fixed': isHeaderFixed}"
  >
    <template v-if="$viewport.isGreaterOrEquals('lg')">
      <div class="container">
        <div class="header__top">
          <div class="--left">
            <CitySelect />
            <div class="header-delimiter" />
            <ShowRooms />
            <div class="header-delimiter" />
            <span class="header-work-time h6">{{ workTime }}</span>
          </div>
          <div class="--right header-top-right-menu">
            <template
              v-for="(itemMenuRight, index) in topRightMenu"
              :key="`itemMenuRight-${itemMenuRight.path}`"
            >
              <div
                v-if="index > 0"
                class="header-delimiter"
              />
              <nuxt-link
                :to="`/${ itemMenuRight.path }`"
                class="hover-decoration"
                @click.capture="(event: any) => clickLinkRightMenu(event, itemMenuRight)"
              >
                {{ itemMenuRight.title }}
              </nuxt-link>
            </template>
          </div>
        </div>
        <div class="header__center">
          <div class="--left">
            <div class="header-socials">
              <a
                v-if="linkTelegram"
                :href="linkTelegram"
                target="_blank"
                rel="nofollow"
                class="--btn-link header-border-button"
              >
                <img
                  src="~/assets/img/svg/social-media/telegram.svg"
                  alt="telegram"
                />
              </a>
              <a
                v-if="linkWhatsapp"
                :href="linkWhatsapp"
                target="_blank"
                rel="nofollow"
                class="--btn-link header-border-button"
              >
                <img
                  src="~/assets/img/svg/social-media/whatsapp.svg"
                  alt="whatsapp"
                />
              </a>
              <a
                v-if="linkPhoneNumber"
                :href="linkPhoneNumber"
                target="_blank"
                rel="nofollow"
                class="--btn-number hover-decoration"
                @click="setYMGoal('phone_click')"
              >
                {{ phoneNumber }}
              </a>
            </div>
          </div>
          <div class="--center">
            <nuxt-link to="/">
              <img
                src="~/assets/img/logo/header-logo-pc.svg"
                alt="MIA SOFIA KIDS"
              >
            </nuxt-link>
          </div>
          <div class="--right">
            <HeaderInputSearch />
            <NuxtLink
              to="/profile"
              class="header-border-button"
            >
              <img
                src="~/assets/img/svg/common/user.svg"
                alt="пользователь"
              />
              <client-only>
                <div
                  v-if="isAuth"
                  class="--badge --green --icon"
                >
                  <img
                    src="~/assets/img/svg/common/check.svg"
                    alt="авторизован"
                  >
                </div>
              </client-only>
            </NuxtLink>
            <nuxt-link
              to="/wishlist"
              class="header-border-button"
              @click="setYMGoal('wishlist_header_click')"
            >
              <client-only>
              <span
                v-if="countWishList > 0"
                class="--badge --red"
              >{{ countWishList }}
              </span>
              </client-only>

              <img
                src="~/assets/img/svg/common/heart.svg"
                alt="избранное"
              />
            </nuxt-link>
            <nuxt-link
              to="/cart"
              class="header-border-button"
              @click.capture="routeCartPage"
            >
              <client-only>
              <span
                v-if="countCartList > 0"
                class="--badge --dark"
              >{{ countCartList }}</span>
              </client-only>
              <img
                src="~/assets/img/svg/common/bag.svg"
                alt="корзина"
              />
            </nuxt-link>
          </div>
        </div>
      </div>
      <div class="header__bottom">
        <MenuCategories />
      </div>
    </template>
    <template v-if="!$viewport.isGreaterOrEquals('lg')">
      <div class="container">
        <div class="header-mobile__top">
          <div class="--left">
            <CitySelect />
          </div>
          <div class="--right">
            <a
              v-if="linkWhatsapp"
              :href="linkWhatsapp"
              target="_blank"
              class="--btn-link"
              rel="nofollow"
            >
              <img
                src="~/assets/img/svg/social-media/whatsapp.svg"
                alt="whatsapp"
              />
            </a>
            <a
              v-if="linkPhoneNumber"
              :href="linkPhoneNumber"
              target="_blank"
              rel="nofollow"
              class="--btn-number"
              @click="setYMGoal('phone_click')"
            >
              {{ phoneNumber }}
            </a>
          </div>
        </div>
        <div class="header-mobile__bottom">
          <div class="--left">
            <div
              class="header-mobile__button button-sidebar"
              :class="{'--open': isOpenSidebar}"
              @click="openSidebar"
            >
              <div />
              <div />
              <div />
            </div>
            <div
              class="header-mobile__button"
              @click="openMobileSearch"
            >
              <img
                src="~/assets/img/svg/common/search.svg"
                alt="поиск"
              />
            </div>
          </div>
          <div class="--center">
            <nuxt-link to="/">
              <img
                src="~/assets/img/logo/header-logo-pc.svg"
                alt="MIA SOFIA KIDS"
              >
            </nuxt-link>
          </div>
          <div class="--right">
            <nuxt-link
              to="/wishlist"
              class="header-mobile__button"
              @click="setYMGoal('wishlist_header_click')"
            >
              <img
                src="~/assets/img/svg/common/heart.svg"
                alt="избранное"
              />
              <client-only>
                <span
                  v-if="countWishList > 0"
                  class="--badge --red"
                >{{ countWishList }}</span>
              </client-only>
            </nuxt-link>
            <nuxt-link
              to="/cart"
              class="header-mobile__button"
              @click.capture="routeCartPage"
            >
              <client-only>
                <span
                  v-if="countCartList > 0"
                  class="--badge --dark"
                >{{ countCartList }}</span>
              </client-only>
              <img
                src="~/assets/img/svg/common/shopping.svg"
                alt="корзина"
              />
            </nuxt-link>
          </div>
        </div>
      </div>
    </template>
  </div>
  <div class="header__placeholder" />
</template>

<script lang="ts" setup>
import { computed, type ComputedRef } from 'vue';
import { storeToRefs } from 'pinia';
import ShowRooms from '~/components/common/ShowRooms.vue';
import CitySelect from '~/components/common/CitySelect.vue';
import MenuCategories from '~/components/header/MenuCategories.vue';
import HeaderInputSearch from '~/components/header/HeaderInputSearch.vue';

import { appConfigStore as useAppConfigStore } from '#sitis/stores/modules/app-store';
import { cmsContentStore } from '#sitis/stores/modules/cms-content-store';
import { wishStore as useWishStore } from '#sitis/stores/modules/wish-store';
import { cartStore as useCartStore } from '#sitis/stores/modules/cart-store';
import { useCommonStore } from '~/store/common';
import { useMobileSidebar } from '~/composables/useMobileSidebar';
import { useMobileSearch } from '~/composables/useMobileSearch';
import { getPhoneNumberLink } from '~/utils/phoneNumber';
import { useCreateOrder3dProject } from '~/composables/useCreateOrder3dProject';
import { useNotificationModal } from '~/composables/useNotificationModal';

const $viewport = useViewport();

const appConfig = useAppConfigStore();
const cmsStore = cmsContentStore();
const wishStore = useWishStore();
const cartStore = useCartStore();
const commonStore = useCommonStore();
const mobileSidebar = useMobileSidebar();
const mobileSearch = useMobileSearch();
const {
  currentUser
} = useSitisAuth();

const { config, vars } = storeToRefs(appConfig);
const {
  openCreateOrder3dProjectOpen
} = useCreateOrder3dProject();
const {
  openNotificationModal
} = useNotificationModal();

const { $ym } = useNuxtApp();

const isHeaderFixed = computed(() => commonStore._headerFixed);
const workTime = computed(() => appConfig?.config?.working_hours || '');
const topRightMenu = computed(() => {
  const items = (cmsStore?.menu || []).filter((itemMenu) => !itemMenu.isHidden && !itemMenu.isHome);
  return items;
});
const linkTelegram = computed(() => appConfig?.vars?.Telegram || appConfig?.config?.telegram || '');
const linkWhatsapp = computed(() => {
  const phone = appConfig?.vars?.Whatsapp || appConfig?.config?.whatsapp || '';
  if (!phone) {
    return '';
  }
  return `https://api.whatsapp.com/send/?phone=${phone}`;
});
const phoneNumber = computed(() => appConfig?.vars?.['Телефон'] || appConfig?.config?.phone || '');
const linkPhoneNumber = computed(() => {
  const phone = appConfig?.vars?.['Телефон'] || appConfig?.config?.phone || '';
  return `tel:${getPhoneNumberLink(phone)}`;
});
const countWishList = computed(() => wishStore.wishlistCount);
const countCartList = computed(() => cartStore.itemsCount);
const isOpenSidebar = computed(() => mobileSidebar.isOpen.value);
const isAuth = computed(() => Boolean(currentUser?.value?.id))

const openSidebar = () => {
  mobileSidebar.changeOpen(!isOpenSidebar.value);
};
const openMobileSearch = () => {
  mobileSearch.changeOpen(true);
};

const routeCartPage = (event: any) => {
  setYMGoalCart();
  if (countCartList.value <= 0) {
    event.preventDefault();
    openNotificationModal({
      title: "Ваша корзина пока пуста.",
      message: `
        <p>1. Выберите товары в каталоге и сложите их в корзину.</p>
        <p>2. Затем перейдите в корзину, чтобы проверить состав заказа и завершить его оформление.</p>
      `
    })
    return
  }
}

function setYMGoal (reach: string) {
  if (typeof $ym === 'function') {
    $ym('reachGoal', reach);
  }
}

function setYMGoalCart () {
  if (typeof $ym === 'function') {
    countCartList.value > 0 ? $ym('reachGoal', 'click_cart_button') : $ym('reachGoal', 'click_empty_cart_button');
  }
}

const clickLinkRightMenu = (event: any, item: any) => {
  if (item.alias === '3d-projects') {
    event.preventDefault();
    event.stopPropagation();
    openCreateOrder3dProjectOpen();
    return;
  }
};

const url = useRequestURL();

useJsonld(() => ({
  '@context': 'https://schema.org',
  '@type': 'LocalBusiness',
  name: 'MiaSofia — магазин детской мебели с доставкой по РФ',
  url: url.origin,
  address: {
    '@type': 'PostalAddress',
    postalCode: '105187',
    addressLocality: config.value?.address_city || 'Москва',
    streetAddress: vars.value?.['Адрес'] || 'Вольная улица, 28/4к1, вход 3Н.'
  },
  telephone: vars.value?.['Телефон'] || '+7 (495) 150-54-02',
  email: vars.value?.email || 'info@mia-sofia.ru',
  logo: config.value?.logoFront || '',
  image: config.value?.logoFront || ''
}));
</script>

<style lang="scss">
.header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 90;
	width: 100%;
	background-color: var(--bg-white);
	border-radius: 0 0 40px 40px;
	padding-top: 14px;
	padding-bottom: 23px;
	box-sizing: border-box;
	border: 1px solid var(--line-color-border-smoky-white, #EBEBEB);
  border-bottom: none;

	&.--fixed {
		padding-top: 16px;
		padding-bottom: 16px;

		.header__top {
			height: 0px;
			overflow: hidden;
			opacity: 0;
		}

		.header__center {
			margin-top: 0;
		}

		.header__center .--center img {
			width: 152px;
			height: 63px;
			transform: translate(-50%, -50%);
		}

		.header__bottom {
			margin-top: 24px;
		}

    .header-menu-categories__arrow-down {
      bottom: -16px;
    }
	}
}

.header__top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 24px;
	transition: all 0.3s;

	.--left, .--right {
		display: flex;
		align-items: center;
	}
}

.header__center {
	display: flex;
	margin-top: 21px;

	& > * {
		flex: 1;
	}

	.--center {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;

		img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) translateY(-22px);
			width: 201px;
			height: 84px;
			transition: filter 0.2s;
		}

		a:hover img {
			filter: opacity(0.7);
		}
	}

	.--right {
		display: flex;
		justify-content: flex-end;

		& > * {
			margin-left: 12px;

			&:first-child {
				margin-left: 0;
			}
		}
	}
}

.header__bottom {
	position: relative;
	z-index: 0;
	margin-top: 32px;
	//background-color: white;
	transition: margin 0.2s;
}

.header__placeholder {
	height: 179px;
}

.header-delimiter {
	width: 1px;
	height: 18px;
	margin: 0 16px;
	background-color: #EBEBEB;
}

.header-work-time {
	color: var(--text-secondary-silver);
}

.header-top-right-menu {
	a {
		color: var(--text-black, #000);
		font-size: 12px;
		font-weight: 500;
	}
}

.header-socials {
	display: flex;
	align-items: center;

	.--btn-link {
		margin-left: 12px;

		&:first-child {
			margin-left: 0;
		}
	}

	.--btn-number {
		margin-left: 16px;
		color: var(--text-black, #000);
		font-size: 24px;
		font-weight: 600;
		cursor: pointer;
	}
}

.header-border-button {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	width: 40px;
	height: 40px;
	border-radius: 9.6px;
	padding: 6px;
	border: 1.2px solid var(--smoky-white, #EBEBEB);
	position: relative;

	img {
		width: 32px;
		height: 32px;
	}

	.--badge {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 4px;
		right: 4px;
		height: 15px;
		min-width: 15px;
		border-radius: 100%;
		color: var(--text-white, #FFF);
		font-size: 9.6px;
		font-weight: 500;

		&.--red {
			background-color: #ED908C;
		}

		&.--dark {
			background-color: #404040;
		}

    &.--green {
      background-color: #D3E6C6;
    }

    &.--icon {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
      }
    }
	}

	&:hover {
		background: var(--smoky-white, #EBEBEB);
	}
}

.header-mobile__top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 8px;
	transition: margin-bottom 0.2s;

	.city-select__name .h6 {
		color: #989898;
	}

	.city-select__name img {
		filter: invert(66%) sepia(4%) saturate(17%) hue-rotate(321deg) brightness(92%) contrast(89%);
	}

	.--right {
		display: flex;
		align-items: center;
	}

	.--btn-link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 15px;
		height: 15px;
		margin-right: 5px;

		img {
			object-fit: contain;
			width: 100%;
			height: 100%;
			filter: invert(66%) sepia(4%) saturate(17%) hue-rotate(321deg) brightness(92%) contrast(89%);
		}
	}

	.--btn-number {
		margin-bottom: -2px;
		color: #989898;
		font-size: 12px;
		line-height: 15px;
		font-weight: 500;
	}
}

.header-mobile__bottom {
	display: flex;
	align-items: flex-end;

	.--left, .--right {
		display: flex;
		align-items: center;
		flex: 1;
		gap: 16px;
	}

	.--center {
		width: 100%;
		max-width: 122px;
		margin: 0 27px;
	}

	.--right {
		justify-content: flex-end;
	}
}

.header-mobile__button {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 28px;
	height: 28px;
	padding: 2px;
	box-sizing: border-box;
	position: relative;

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	.--badge {
		position: absolute;
		top: -2px;
		right: -5px;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 15px;
		min-width: 15px;
		border-radius: 100%;

		color: #FFF;
		font-size: 8px;
		font-weight: 500;

		&.--red {
			background-color: #ED908C;
		}

		&.--dark {
			background-color: #404040;
		}
	}
}

.button-sidebar {
	flex-direction: column;

	div {
		width: 20px;
		height: 2px;
		background-color: black;
		border-radius: 100px;
		margin-top: 4px;

		&:first-child {
			margin-top: 0;
		}
	}

	&.--open {
		div {
			margin: 0;
		}

		div:nth-child(1) {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(-45deg);
		}

		div:nth-child(3) {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(45deg);
		}

		div:nth-child(2) {
			display: none;
		}
	}
}

@media (max-width: 1439px) {
	.header {
		border-radius: 0 0 30px 30px;
	}
}

@media (max-width: 1199px) {
	.header {
		padding-top: 10px;
		padding-bottom: 16px;
	}
	.header__bottom {
		margin-top: 24px;
	}

  .header-delimiter {
    margin: 0 8px;
  }
  .header-socials {
    & > * {
      margin-left: 6px;
    }

    .--btn-number {
      margin-left: 8px;
      font-size: 20px;
    }
  }
  .header__center .--right > * {
    margin-left: 8px;
  }
}

@media (max-width: 1023px) {
	.header {
		padding: 8px 0;
		padding-bottom: 12px;
		border-radius: 0 0 24px 24px;
		transition: padding 0.2s;
    border-bottom: 1px solid var(--line-color-border-smoky-white, #EBEBEB);

		&.--fixed {
			padding: 4px 0;

			.header-mobile__top {
				margin-bottom: -4px;
			}
		}
	}
	.header__placeholder {
		height: 104.14px;
	}
}
</style>

<template>
  <div class="city-select">
    <div
      class="city-select__name hover-decoration"
      @click="openCitySelect"
    >
      <span class="h6">{{ currentCity }}</span>
      <img
        src="~/assets/img/svg/common/arrow-down.svg"
        alt="развернуть"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { appConfigStore } from '#sitis/stores/modules/app-store';
import { useUserCity } from '#imports';

const appConfig = appConfigStore();
const { changeSelectPopupOpen } = useUserCity();

const currentCity = computed(() => appConfig?.config?.city?.model?.name || '');

const openCitySelect = () => {
  changeSelectPopupOpen(true);
};
</script>

<style lang="scss">
.city-select {
}

.city-select__name {
	display: flex;
	align-items: center;
	cursor: pointer;

	img {
		margin-left: 1px;
    width: 20px;
    height: 20px;
	}
}
</style>

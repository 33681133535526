<template>
  <div class="showrooms-select">
    <nuxt-link to="/contact-us" class="showrooms-select__name">
      <span class="h6">Шоурумы</span>
      <img
        src="~/assets/img/svg/common/arrow-down.svg"
        alt="развернуть"
      />
    </nuxt-link>
  </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" scoped>
.showrooms-select {}

.showrooms-select__name {
  display: flex;
  align-items: center;

  span {}

  img {
    margin-left: 1px;
    width: 20px;
    height: 20px;
  }

  &:hover {
    span {
      text-decoration: underline;
    }
  }
}
</style>
